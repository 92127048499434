import React, { lazy, Suspense } from 'react';
import { useInView } from 'react-intersection-observer';
import Layout from 'templates/Layout/Layout';
import { Identity, Reviews, Services, FormContainer } from 'components/Homepage';
import InformationContent from 'content/homepage-information.json';
import Information from 'components/_shared/Information/Information';
import ContentData from 'content/homepage-informationOffice.json';
import InformationOffice from 'components/_shared/InformationOffice/InformationOffice';

const ContactForm = lazy(() => import('components/_shared/ContactForm/ContactForm'));

const Home = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <Layout metaTitle="Strona główna" metaDescription="Zespół doświadczonych specjalistów...">
      <Identity />
      <Reviews />
      <Information title={InformationContent.title} description={InformationContent.description} image="homepage-information-1.jpg" />
      <Services />
      <InformationOffice contentData={ContentData} />
      <div ref={ref}>
        {inView && (
          <Suspense>
            <FormContainer>
              <ContactForm title="Wyślij nam zapytanie ofertowe" />
            </FormContainer>
          </Suspense>
        )}
      </div>
    </Layout>
  );
};

export default Home;
