import styled from 'styled-components';
import Button from 'components/_shared/Button/Button';
import Container from 'components/_shared/Container/Container';
import { fontWeight, colors, breakPoints } from 'utils/variables';

export const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: rgb(255, 251, 244);
  background-image: linear-gradient(180deg, rgba(255, 251, 244, 1) 0%, rgba(255, 255, 255, 1) 100%);
  height: calc(100vh - 8rem);

  @media (min-width: 376px) {
    min-height: 800px;
  }

  @media (min-width: ${breakPoints.tablet}) {
    height: calc(100vh - 11rem);
    min-height: 600px;
  }

  @media (min-width: ${breakPoints.desktop}) {
    height: calc(100vh - 17.1rem);
    min-height: 740px;
  }

`;

export const StyledContainer = styled(Container)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: ${breakPoints.tablet}) {
    flex-direction: row;
  }

  .image-wrapper {
    box-shadow: rgb(255 237 203) 1rem 1rem 0px 0px;

  }
`;

export const StyledContent = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 3rem;
  width: 100%;
  max-width: 75rem;

  animation: slideFromBottom 1s;

  @keyframes slideFromBottom {
    0% {
      opacity: 0;
      transform: translateY(200px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  @media (min-width: ${breakPoints.tablet}) {
    margin-top: 2rem;
    width: auto;
    max-width: unset;
    min-width: 38rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    margin-top: -3.6rem;
  }
`;

export const StyledTitle = styled.h1`
  font-size: 3rem;
  line-height: 4.2rem;
  font-weight: ${fontWeight.regular};
  color: ${colors.darkBlue};
  margin: 0 0 2rem 0;
  padding: 0;
  text-align: center;

  @media (min-width: ${breakPoints.tablet}) {
    text-align: right;
    font-size: 3.5rem;
    line-height: 5rem;
    margin-bottom: 2.4rem;
    margin-left: 4rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    font-size: 5.6rem;
    line-height: 7rem;
    margin-bottom: 2.7rem;
    margin-left: unset;
  }
`;

export const StyledDescription = styled.span`
  font-size: 1.5rem;
  line-height: 2.2rem;
  font-weight: ${fontWeight.regular};
  color: ${colors.grayCobalt};
  margin-bottom: 3rem;
  text-align: center;

  @media (min-width: ${breakPoints.tablet}) {
    text-align: right;
    font-size: 1.6rem;
    line-height: 2.6rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }
`;

export const StyledButton = styled(Button)`
  display: block;
  margin: auto;

  @media (min-width: ${breakPoints.tablet}) {
    margin-left: auto;
    margin-right: 0.4rem;
  }
`;

export const StyledShape1 = styled.div`
  pointer-events: none;
  display: inline-block;
  position: absolute;
  height: 68.5rem;
  width: 67rem;
  left: -34rem;
  bottom: 8rem;
  transform: rotate(90deg);

  @media (min-width: ${breakPoints.desktop}) {
    left: -45.5rem;
    bottom: 13.6rem;
    transform: rotate(0);
  }
`;

export const StyledShape2 = styled.div`
  display: none;
  pointer-events: none;

  @media (min-width: ${breakPoints.desktop}) {
    display: inline-block;
    position: absolute;
    height: 52.4rem;
    width: 68rem;
    left: -33rem;
    bottom: 0rem;
  }
`;
