import React from 'react';
import ContentData from 'content/homepage-services.json';
import { colors } from 'utils/variables';
import StaticShape from 'components/_shared/AloneShapes/StaticShape/StaticShape';
import {
  StyledBackground,
  StyledContainer,
  StyledWrapper,
  StyledImage,
  StyledContent,
  StyledTitle,
  StyledDescription,
  StyledShape1,
  StyledServices,
} from './Services.styled';
import ServiceTile from './ServiceTile/ServiceTile';

const getService = (service, index) => <ServiceTile key={`${service.title}-${index}`} service={service} />;

const Services = () => (
  <StyledWrapper>
    <StyledBackground />
    <StyledContainer>
      <StyledShape1>
        <StaticShape
          name="Services-2"
          originalWidth={538}
          originalHeight={547}
          fill={colors.peachMilk}
          path="M 0.1 0 L 26.8 0 C 26.855 0 26.9 0.045 26.9 0.1 L 26.9 27.25 C 26.9 27.305 26.855 27.35 26.8 27.35 L 0.1 27.35 C 0.045 27.35 0 27.305 0 27.25 L 0 0.1 C 0 0.045 0.045 0 0.1 0 Z"
        />
      </StyledShape1>
      <StyledContent>
        <StyledTitle dangerouslySetInnerHTML={{ __html: ContentData.title }} />
        <StyledDescription dangerouslySetInnerHTML={{ __html: ContentData.description }} />
        <StyledServices>{ContentData.services.map(getService)}</StyledServices>
      </StyledContent>
      <StyledImage src="homepage-services-1.jpg" />
    </StyledContainer>
  </StyledWrapper>
);

export default Services;
