import styled from 'styled-components';
import Image from 'components/_shared/Image/Image';
import { fontWeight, colors, breakPoints } from 'utils/variables';
import Container from 'components/_shared/Container/Container';

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledContainer = styled(Container)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column-reverse;
  padding: 0 0 10rem 0;
  max-width: 75rem;
  margin: auto;

  @media (min-width: ${breakPoints.tablet}) {
    max-width: unset;
    flex-direction: row;
    padding: 8.2rem 0 5rem 0;
  }

  @media (min-width: ${breakPoints.desktop}) {
    padding: 8.2rem 0 14.4rem 0;
  }
`;

export const StyledImage = styled(Image)`
  width: 100%;
  height: auto;
  margin-top: 4rem;
  max-width: 75rem;
  max-height: 30rem;

  @media (min-width: ${breakPoints.tablet}) {
    width: 62rem;
    height: 40rem;
    max-width: unset;
    max-height: unset;
  }

  @media (min-width: ${breakPoints.fullsize}) {
    margin-left: -12.8rem;
    width: 71.3rem;
    height: 47.468rem;
    max-width: unset;
  }
`;

export const StyledContent = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 3rem;
  width: 100%;
  max-width: 75rem;

  @media (min-width: ${breakPoints.tablet}) {
    margin-top: 7.6rem;
    margin-left: 4rem;
    max-width: 42rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    margin-left: auto;
    max-width: 50rem;
  }
`;

export const StyledTitle = styled.h2`
  padding: 0;
  font-size: 2.4rem;
  line-height: 3.6rem;
  font-weight: ${fontWeight.medium};
  color: ${colors.darkBlue};
  margin: 0 0 2rem 0;

  @media (min-width: ${breakPoints.desktop}) {
    font-size: 3.2rem;
    line-height: 4.8rem;
    margin-bottom: 3.1rem;
  }
`;

export const StyledDescription = styled.span`
  font-size: 1.4rem;
  line-height: 3.2rem;
  font-weight: ${fontWeight.regular};
  color: ${colors.grayCobalt};
  margin-bottom: 3rem;

  @media (min-width: ${breakPoints.desktop}) {
    font-size: 1.6rem;
    line-height: 3.8rem;
  }
`;

export const StyledShape1 = styled.div`
  display: inline-block;
  position: absolute;
  pointer-events: none;
  height: 50.5rem;
  width: 36.5rem;
  left: -35rem;
  top: -15rem;
  transform: rotate(270deg);

  @media (min-width: ${breakPoints.desktop}) {
    transform: rotate(90deg);
    right: unset;
    left: 25.5rem;
    top: -7rem;
  }
`;

export const StyledShape2 = styled.div`
  display: none;
  pointer-events: none;

  @media (min-width: ${breakPoints.desktop}) {
    display: inline-block;
    position: absolute;
    height: 68.6rem;
    width: 52.8rem;
    left: 9rem;
    top: 5.5rem;
  }
`;

export const StyledShape3 = styled.div`
  display: none;
  pointer-events: none;

  @media (min-width: ${breakPoints.tablet}) {
    display: inline-block;
    position: absolute;
    height: 41.426rem;
    width: 41.426rem;
    right: -28rem;
    top: -4.5rem;
  }
`;
