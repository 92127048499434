import React from 'react';
import PropTypes from 'prop-types';
import { StyledWrapper, StyledStar } from './Stars.styled';

const Stars = ({ count, small }) => {
  const getStar = (_star, index) => <StyledStar key={index} active={index + 1 <= count} small={small} />;

  return <StyledWrapper small={small}>{[...Array(5)].map(getStar)}</StyledWrapper>;
};

Stars.propTypes = {
  count: PropTypes.number.isRequired,
  small: PropTypes.bool,
};

Stars.defaultProps = {
  small: false,
};

export default Stars;
