import React, { useState, useEffect } from 'react';
import loadable from '@loadable/component';
import { useStaticQuery, graphql } from 'gatsby';
import Carousel from 'nuka-carousel';

import useWindowSize from 'utils/useWindowSize';

import MainReviewTile from './components/MainReviewTile/MainReviewTile';
import StandardReviewTile from './components/StandardReviewTile/StandardReviewTile';
import { StyledWrapper, StyledContainer, StyledContent, StyledButton, StyledShape1, StandardReviewsWrapper } from './Reviews.styled';

const AnimatedShape =  loadable(() => import('components/_shared/AloneShapes/AnimatedShape/AnimatedShape'));

const ReviewsWrapper = ({ children, condition, wrapper }) => (condition ? wrapper(children) : children);
const Reviews = () => {
  const {
    data: { mainReview, reviews, button },
  } = useStaticQuery(graphql`
    query HomepageReviews {
      data: reviewsJson {
        id
        mainReview {
          stars
          imageSrc
          name
          title
          description
          readMoreLink
        }
        reviews {
          stars
          imageSrc
          name
          title
          description
        }
        button {
          text
          title
          link
        }
      }
    }
  `);

  const [showCarousel, setShowCarousel] = useState(true);
  const windowSize = useWindowSize();

  useEffect(() => {
    setShowCarousel(windowSize === 'mobile');
  }, [windowSize]);

  const renderReview = (review) => <StandardReviewTile key={review.title} href={button.link} review={review} />;
  const renderCarouselWrapper = (children) => (
    <Carousel renderCenterLeftControls={null} renderCenterRightControls={null} pauseOnHover autoplay wrapAround>
      {children}
    </Carousel>
  );

  return (
    <StyledWrapper>
      <StyledContainer>
        <StyledShape1>
          <AnimatedShape
            name="Reviews-1"
            originalWidth={505}
            originalHeight={365}
            paths={[
              ' M 9.8 16.652 C 8.166 17.518 7.075 18.274 6.125 18.249 C 5.4 18.23 5.4 18.249 4.11 18.249 C 2.302 18.249 0 16.096 0 14.006 C 0 12.134 0 11.057 0 9.763 C 0 9.042 0.681 7.696 1.016 7.128 C 2.023 5.421 3.824 4.374 6 3.121 C 8.158 1.879 14.225 0.103 16.55 0.028 C 18.829 -0.046 15.675 0.053 18 0.028 C 23.455 -0.031 25.25 5.757 25.25 9.139 C 25.25 12.027 24.95 13.432 24.125 14.705 C 23.642 15.451 21.575 17.975 19.173 17.7 C 17.119 17.466 16.3 17.336 15.125 16.502 C 14 15.703 13.777 15.611 12.125 15.653 C 11.15 15.678 11.025 16.003 9.8 16.652 Z ',
              ' M 2.21 7.083 C 1.012 5.902 -0.034 5.114 0.001 4.427 C 0.027 3.903 0.001 3.903 0.001 2.971 C 0.001 1.664 2.98 0 5.871 0 C 8.462 0 9.952 0 11.742 0 C 12.74 0 14.602 0.492 15.388 0.734 C 17.75 1.462 19.198 2.764 20.932 4.337 C 22.65 5.896 25.108 10.281 25.211 11.962 C 25.314 13.609 25.177 11.329 25.211 13.01 C 25.293 16.953 17.285 18.25 12.605 18.25 C 8.61 18.25 6.666 18.033 4.904 17.437 C 3.872 17.088 0.38 15.594 0.76 13.858 C 1.084 12.373 1.264 11.781 2.418 10.932 C 3.523 10.119 3.651 9.958 3.593 8.764 C 3.558 8.059 3.108 7.969 2.21 7.083 Z ',
              ' M 23.04 11.167 C 24.238 12.348 25.284 13.136 25.249 13.823 C 25.223 14.347 25.249 14.347 25.249 15.279 C 25.249 16.586 22.27 18.25 19.379 18.25 C 16.788 18.25 15.298 18.25 13.508 18.25 C 12.51 18.25 10.648 17.758 9.862 17.516 C 7.5 16.788 6.052 15.486 4.318 13.913 C 2.6 12.354 0.142 7.969 0.039 6.288 C -0.064 4.641 0.073 6.921 0.039 5.24 C -0.043 1.297 7.965 0 12.645 0 C 16.64 0 18.584 0.217 20.346 0.813 C 21.378 1.162 24.87 2.656 24.49 4.392 C 24.166 5.877 23.986 6.469 22.832 7.318 C 21.727 8.131 21.599 8.292 21.657 9.486 C 21.692 10.191 22.142 10.281 23.04 11.167 Z ',
            ]}
          />
        </StyledShape1>
        <StyledContent>
          <MainReviewTile review={mainReview} />
          <StandardReviewsWrapper showCarousel={showCarousel}>
            <ReviewsWrapper condition={showCarousel} wrapper={renderCarouselWrapper}>
              {reviews.map(renderReview)}
            </ReviewsWrapper>
          </StandardReviewsWrapper>
          <StyledButton
            as="a"
            title={button.title}
            dangerouslySetInnerHTML={{ __html: button.text }}
            href={button.link}
            target="_blank"
            rel="noopener noreferrer"
          />
        </StyledContent>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default Reviews;
