import React from 'react';
import PropTypes from 'prop-types';
import { StyledWrapper, StyledDescription, StyledImage, StyledName, StyledTitle } from './StandardReviewTile.styled';
import Stars from '../Stars/Stars';

const StandardReviewTile = ({ review, href }) => (
  <StyledWrapper href={href} target="_blank" rel="noopener noreferrer">
    <StyledImage src={review.imageSrc} />
    <StyledName dangerouslySetInnerHTML={{ __html: review.name }} />
    <StyledTitle dangerouslySetInnerHTML={{ __html: review.title }} />
    <Stars count={review.stars} small />
    <StyledDescription dangerouslySetInnerHTML={{ __html: review.description }} />
  </StyledWrapper>
);

StandardReviewTile.propTypes = {
  href: PropTypes.string.isRequired,
  review: PropTypes.shape({
    stars: PropTypes.number,
    imageSrc: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default StandardReviewTile;
