import React from 'react';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import StaticShape from 'components/_shared/AloneShapes/StaticShape/StaticShape';
import { colors } from 'utils/variables';
import {
  StyledWrapper,
  StyledContainer,
  StyledImage,
  StyledContent,
  StyledTitle,
  StyledDescription,
  StyledShape1,
  StyledShape2,
  StyledShape3,
} from './Information.styled';

const AnimatedShape =  loadable(() => import('components/_shared/AloneShapes/AnimatedShape/AnimatedShape'));

const Information = ({ title, description, image }) => (
  <StyledWrapper>
    <StyledContainer>
      <StyledShape2>
        <StaticShape
          name="Information-2"
          originalWidth={528}
          originalHeight={686}
          fill={colors.peachMilk}
          path="M 0.1 0 L 26.3 0 C 26.355 0 26.4 0.045 26.4 0.1 L 26.4 34.2 C 26.4 34.255 26.355 34.3 26.3 34.3 L 0.1 34.3 C 0.045 34.3 0 34.255 0 34.2 L 0 0.1 C 0 0.045 0.045 0 0.1 0 Z"
        />
      </StyledShape2>
      <StyledShape3>
        <AnimatedShape
          name="Information-3"
          originalWidth={430}
          originalHeight={430}
          fill={colors.peachMilk}
          paths={[
            ' M 1.632 5.84 L 11.893 0.914 C 13.38 0.2 15.225 0.799 16.01 2.25 L 21.141 11.737 C 21.925 13.188 21.355 14.946 19.868 15.66 L 9.607 20.586 C 8.12 21.3 6.275 20.701 5.49 19.25 L 0.359 9.763 C -0.425 8.312 0.145 6.554 1.632 5.84 Z ',
            ' M 0.831 13.726 L 4.603 2.987 C 5.15 1.43 6.878 0.549 8.459 1.02 L 18.795 4.1 C 20.376 4.572 21.216 6.218 20.669 7.774 L 16.897 18.513 C 16.35 20.07 14.622 20.951 13.041 20.48 L 2.705 17.4 C 1.124 16.928 0.284 15.282 0.831 13.726 Z ',
          ]}
        />
      </StyledShape3>
      <StyledShape1>
        <StaticShape
          name="Information-1"
          originalWidth={365}
          originalHeight={505}
          path=" M 2.159 23.25 C 0.434 21.772 0 20.085 0 17.951 C 0 16.423 0.487 12.571 1.849 9.5 C 3.058 6.773 3.889 4.269 5.697 2.5 C 7.22 1.01 9.614 0 11.043 0 C 12.227 0 12.493 0 13.543 0 C 13.998 0 14.276 0.233 14.892 0.45 C 15.527 0.673 16.106 1.199 16.641 1.6 C 17.376 2.151 18.24 4.033 18.24 4.75 C 18.24 6.083 18.273 4.556 18.219 6.125 C 18.143 8.341 15.728 10.753 15.642 12.65 C 15.565 14.34 17.741 18.656 17.741 19.4 C 17.741 22.646 14.226 25.25 11.844 25.25 C 10.78 25.25 7.774 25.25 6.796 25.25 C 5.547 25.25 3.445 24.352 2.159 23.25 Z "
        />
      </StyledShape1>
      <StyledImage src={image} />
      <StyledContent>
        <StyledTitle dangerouslySetInnerHTML={{ __html: title }} />
        <StyledDescription dangerouslySetInnerHTML={{ __html: description }} />
      </StyledContent>
    </StyledContainer>
  </StyledWrapper>
);

Information.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default Information;
