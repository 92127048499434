import styled from 'styled-components';
import Image from 'components/_shared/Image/Image';
import { fontWeight, colors, breakPoints } from 'utils/variables';
import Container from 'components/_shared/Container/Container';

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledContainer = styled(Container)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 0 10rem 0;
  max-width: 75rem;
  margin: auto;

  @media (min-width: ${breakPoints.tablet}) {
    padding: 0rem 0 8rem 0;
    max-width: unset;
  }

  @media (min-width: ${breakPoints.desktop}) {
    flex-direction: row;
    padding: 15rem 0 5rem 0;
  }
`;

export const StyledBackground = styled.div`
  position: absolute;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: ${colors.peachMilk};

  @media (min-width: ${breakPoints.desktop}) {
    display: none;
  }
`;

export const StyledImage = styled(Image)`
  width: 100%;
  height: auto;
  margin-top: -4.6rem;
  max-width: 41rem;
  max-height: 45.1rem;

  @media (min-width: ${breakPoints.desktop}) {
    margin-right: 6.4rem;
  }
`;

export const StyledContent = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 5rem;
  width: 100%;

  @media (min-width: ${breakPoints.tablet}) {
    margin-top: 7rem;
    max-width: 70rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    margin-top: 0;
    max-width: 61.4rem;
  }
`;

export const StyledTitle = styled.h2`
  padding: 0;
  font-size: 2.4rem;
  line-height: 3.6rem;
  font-weight: ${fontWeight.medium};
  color: ${colors.darkBlue};
  margin: 0 0 2rem 0;

  @media (min-width: ${breakPoints.desktop}) {
    font-size: 3.2rem;
    line-height: 4.8rem;
    margin-bottom: 2rem;
  }
`;

export const StyledDescription = styled.p`
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: ${fontWeight.light};
  color: ${colors.grayCobalt};
  margin-bottom: 4.6rem;

  @media (min-width: ${breakPoints.desktop}) {
    /* max-width: 47.2rem; */
  }
`;

export const StyledShape1 = styled.div`
  display: none;
  pointer-events: none;

  @media (min-width: ${breakPoints.desktop}) {
    display: inline-block;
    position: absolute;
    height: 54.7rem;
    width: 53.8rem;
    right: 0;
    top: 8.3rem;
  }
`;

export const StyledServices = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
  margin-bottom: 6rem;

  @media (min-width: ${breakPoints.desktop}) {
    width: 61.4rem;
    margin-bottom: 0;
  }
`;
