import styled from 'styled-components';
import { fontWeight, colors, breakPoints } from 'utils/variables';

export const StyledWrapper = styled.div`
  position: relative;
  margin-left: 4.1rem;
  margin-bottom: 4.7rem;

  @media (min-width: ${breakPoints.tablet}) {
    width: 30rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    width: 24.3rem;
  }
`;

export const StyledIcon = styled.svg`
  display: block;
  position: absolute;
  height: 2.5rem;
  width: 2.5rem;
  left: -4.2rem;
`;

export const StyledTitle = styled.h3`
  padding: 0;
  font-size: 1.8rem;
  line-height: 2.6rem;
  font-weight: ${fontWeight.medium};
  color: ${colors.darkBlue};
  margin: 0 0 2.3rem 0;
  width: 26rem;

  @media (min-width: ${breakPoints.desktop}) {
    width: auto;
  }
`;
export const StyledDescription = styled.span`
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-weight: ${fontWeight.light};
  color: ${colors.grayCobalt};
`;
