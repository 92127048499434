import styled from 'styled-components';
import { fontWeight, colors, breakPoints } from 'utils/variables';
import Image from 'components/_shared/Image/Image';
import hexToRGBA from 'utils/hexToRGBA';

export const StyledWrapper = styled.a`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 0.2rem;
  border: 0.1rem solid ${hexToRGBA(colors.black, 0.05)};
  padding: 3.2rem 0;
  background-color: ${colors.white};
  box-shadow: 0.4rem 0.4rem 0 0 ${colors.butter};
  margin: 0 auto;
  width: 80vw;
  height: 33rem;
  transition: all 300ms ease;
  text-decoration: none;

  @media (min-width: ${breakPoints.tablet}) {
    width: 32%;
    height: 30rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    width: 38rem;
    height: 28rem;
  }

  &:first-child,
  &:last-child {
    @media (min-width: ${breakPoints.tablet}) {
      margin: 0;
    }
  }

  &:hover,
  &:focus {
    box-shadow: 0rem 0rem 0 0 ${colors.butter};
  }
`;

export const StyledDescription = styled.span`
  font-size: 1.4rem;
  font-weight: ${fontWeight.light};
  line-height: 2.1rem;
  color: ${colors.veryDarkBlue};
  text-align: center;
  margin: 1.5rem 0 0 0;
  width: 80%;

  @media (min-width: ${breakPoints.desktop}) {
    width: 34.8rem;
  }
`;

export const StyledImage = styled(Image)`
  min-width: 7.2rem;
  min-height: 7.2rem;
`;

export const StyledName = styled.h3`
  padding: 0;
  font-size: 1.8rem;
  font-weight: ${fontWeight.medium};
  line-height: 2.8rem;
  color: ${colors.veryDarkBlue};
  text-align: center;
  margin: 1.5rem 0 -0.2rem 0;
`;

export const StyledTitle = styled.span`
  font-size: 1.4rem;
  font-weight: ${fontWeight.light};
  line-height: 2.8rem;
  color: ${colors.frozenSilver};
  text-align: center;
  margin-bottom: 1.35rem;
`;
