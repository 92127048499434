import styled from 'styled-components';
import { fontWeight, colors, breakPoints } from 'utils/variables';
import Image from 'components/_shared/Image/Image';

export const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column-reverse;

  @media (min-width: ${breakPoints.tablet}) {
    flex-direction: row;
  }
`;

export const StyledContent = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  border-right: 0.1rem solid ${colors.butter};
  padding: 1.6rem 3.5rem 1.6rem 0;
  width: 65%;

  @media (min-width: ${breakPoints.tablet}) {
    padding: 1.6rem 5.1rem 1.6rem 0;
    width: 55%;
  }

  @media (min-width: ${breakPoints.tablet}) {
    width: 100%;
  }
`;

export const StyledDescription = styled.span`
  font-size: 1.3rem;
  line-height: 2.3rem;
  font-weight: ${fontWeight.light};
  color: ${colors.veryDarkBlue};
  text-align: right;
  margin: 2rem 0 1.5rem 0;
  max-width: 28.4rem;

  @media (min-width: ${breakPoints.tablet}) {
    font-size: 1.8rem;
    line-height: 2.8rem;
    max-width: 30rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    max-width: 40rem;
  }
`;

export const StyledLink = styled.a`
  font-size: 1.2rem;
  line-height: 2.5rem;
  font-weight: ${fontWeight.medium};
  color: ${colors.peach};
  text-decoration: none;
  letter-spacing: 0.01rem;
  border-bottom: 0.1rem solid transparent;
  transform: translate(0rem, 0.3rem);

  @media (min-width: ${breakPoints.tablet}) {
    font-size: 1.4rem;
    line-height: 2.6rem;
  }

  &:after {
    content: '>';
    font-size: 1.6rem;
    transform: translate(0rem, 0.2rem);
    display: inline-block;
    margin-left: 0.7rem;

    @media (min-width: ${breakPoints.tablet}) {
      font-size: 1.8rem;
    }
  }

  &:hover {
    border-bottom: 0.1rem solid ${colors.peach};
  }
`;

export const StyledIdentity = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding-left: 1rem;
  width: 55%;

  @media (min-width: ${breakPoints.tablet}) {
    padding-left: 4.85rem;
    width: 45%;
  }

  @media (min-width: ${breakPoints.desktop}) {
    align-items: flex-start;
    width: 100%;
  }
`;

export const StyledImage = styled(Image)`
  opacity: 0;
  width: 7.2rem;
  height: 7.2rem;

  animation: ${({ isVisible }) => isVisible && 'slideImageToTop 1s .1s forwards'};

  @keyframes slideImageToTop {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`;

export const StyledName = styled.h2`
  opacity: 0;
  padding: 0;
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: ${fontWeight.medium};
  color: ${colors.veryDarkBlue};
  text-align: center;
  margin: 2.4rem 0 0.4rem 0;

  animation: ${({ isVisible }) => isVisible && 'slideNameToTop .5s forwards'};
  
  @keyframes slideNameToTop {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  @media (min-width: ${breakPoints.tablet}) {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    text-align: left;
  }
`;

export const StyledTitle = styled.span`
  opacity: 0;
  font-size: 1.3rem;
  line-height: 1.7rem;
  font-weight: ${fontWeight.light};
  color: ${colors.frozenSilver};
  text-align: center;

  animation: ${({ isVisible }) => isVisible && 'slideTitleToTop .5s forwards'};
  
  @keyframes slideTitleToTop {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  @media (min-width: ${breakPoints.tablet}) {
    font-size: 1.4rem;
    text-align: left;
    max-width: 18rem;
  }
`;
