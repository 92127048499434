import React from 'react';
import PropTypes from 'prop-types';
import Icon1 from 'images/serviceTile-1.inline.svg';
import { StyledWrapper, StyledTitle, StyledDescription, StyledIcon } from './ServiceTile.styled';

const ServiceTile = ({ service }) => (
  <StyledWrapper>
    <StyledIcon as={Icon1} />
    <StyledTitle dangerouslySetInnerHTML={{ __html: service.title }} />
    <StyledDescription dangerouslySetInnerHTML={{ __html: service.description }} />
  </StyledWrapper>
);

ServiceTile.propTypes = {
  service: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default ServiceTile;
