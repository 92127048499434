import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import useScrollVisable from 'utils/useScrollVisable';

import {
  StyledWrapper,
  StyledContent,
  StyledDescription,
  StyledLink,
  StyledIdentity,
  StyledImage,
  StyledName,
  StyledTitle,
} from './MainReviewTile.styled';
import Stars from '../Stars/Stars';

const MainReviewTile = ({ review }) => {
  const wrapperRef = useRef(null);
  const [isElementOnScreen] = useScrollVisable(wrapperRef);

  return (
    <StyledWrapper>
      <StyledContent>
        <Stars count={review.stars} />
        <StyledDescription dangerouslySetInnerHTML={{ __html: review.description }} />
        <StyledLink target="_blank" rel="noopener noreferrer" href={review.readMoreLink} title="Sprawdź te opinię w Google">
          Przeczytaj całość
        </StyledLink>
      </StyledContent>
      <StyledIdentity ref={wrapperRef}>
        <StyledImage src={review.imageSrc} isVisible={isElementOnScreen} />
        <StyledName dangerouslySetInnerHTML={{ __html: review.name }} isVisible={isElementOnScreen}/>
        <StyledTitle dangerouslySetInnerHTML={{ __html: review.title }} isVisible={isElementOnScreen}/>
      </StyledIdentity>
    </StyledWrapper>
  );
};

MainReviewTile.propTypes = {
  review: PropTypes.shape({
    stars: PropTypes.number,
    imageSrc: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    readMoreLink: PropTypes.string,
  }).isRequired,
};

export default MainReviewTile;
