import styled from 'styled-components';
import Button from 'components/_shared/Button/Button';
import { breakPoints } from 'utils/variables';
import Container from 'components/_shared/Container/Container';

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledContainer = styled(Container)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4.7rem 0 6rem 0;
`;

export const StyledContent = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  display: block;
`;

export const StyledShape1 = styled.div`
  display: inline-block;
  position: absolute;
  pointer-events: none;
  height: 36.5rem;
  width: 50.5rem;
  right: -12.5rem;
  top: 50rem;

  @media (min-width: ${breakPoints.tablet}) {
    top: 30.3rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    right: -12.3rem;
    top: 13rem;
  }
`;

export const StandardReviewsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 9.4rem 0 3.3rem 0;
  width: 100%;

  @media (min-width: ${breakPoints.tablet}) and (max-width: ${breakPoints.desktop}) {
    & > div:last-child {
      display: none;
      visibility: hidden;
      opacity: 0;
    }
  }

  & > div.slider .slider-frame {
    padding-bottom: 10px !important; // fix for cutting elements in the slider
  }
`;
