import styled, { css } from 'styled-components';
import { colors } from 'utils/variables';

export const StyledWrapper = styled.div`
  margin-right: ${({ small }) => (small ? '0' : '-0.7rem')};
`;

export const StyledStar = styled.div`
  display: inline-block;

  &:before {
    content: '★';
    font-size: ${({ small }) => (small ? '1.8rem' : '2.4rem')};
    color: ${({ active }) => (active ? colors.peach : colors.white)};
    line-height: ${({ small }) => (small ? '1.8rem' : '2.4rem')};
    display: block;
    margin: 0 0.2rem;

    ${({ active }) =>
      !active &&
      css`
        transform: translateY(-0.2rem);
        margin: ${({ small }) => (small ? '0 0.4rem' : '0 0.5rem')};
        font-size: ${({ small }) => (small ? '1.4rem' : '1.8rem')};
        -webkit-text-stroke: ${({ small }) => (small ? `0.1rem ${colors.peach}` : `0.2rem ${colors.peach}`)};
        -webkit-text-fill-color: ${colors.white};
      `}
  }
`;
