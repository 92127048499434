import { useState, useEffect } from 'react';
import throttle from './throttle';

const useScrollVisable = (element) => {
  const [isElementOnScreen, setIsElementOnScreeen] = useState(false);
  const handleScroll = throttle(() => {
    const partOfWindowHeight = Math.floor(window.innerHeight * 0.8);
    const elementOffsetFromTop = element.current.getBoundingClientRect().top;

    if (elementOffsetFromTop <= partOfWindowHeight) {
      setIsElementOnScreeen(true);
      window.removeEventListener('scroll', handleScroll);
    }
  }, 30);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return [isElementOnScreen];
};

export default useScrollVisable;
